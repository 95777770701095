import { AnimationControls, motion, VariantLabels, SVGMotionProps } from 'framer-motion'

const SVG = (
    pathData: string,
    width: number | string,
    height: number | string,
    origWidth: number,
    origHeight: number,
    color: string,
    controller: AnimationControls,
    name: string,
    strokeTime: number = 0.8,
    fillTime: number = 1.0,
    initialProps: Record<string, number | string | Record<string, number | string>> = {},
    finalProps: Record<string, number | string | Record<string, number | string>> = {},
    strokeWidth: number = 2
) => {
    const icon: Record<PropertyKey, Record<string, number | string | Record<string, number | string>>> = {
        hidden: {
            opacity: 0,
            pathLength: 0,
            fill: color + '00',
            ...initialProps,
        },
        visible: {
            opacity: 1,
            pathLength: 1,
            fill: color,
            ...finalProps,
        },
    }

    return (
        <motion.svg height={height} viewBox={`0 0 ${origWidth} ${origHeight}`} xmlns="http://www.w3.org/2000/svg" name={name}>
            <motion.path
                fillRule="evenodd"
                // clipRule="evenodd"
                d={pathData}
                fill={color}
                strokeWidth={strokeWidth}
                stroke={color}
                variants={icon}
                initial="hidden"
                // animate="visible"
                animate={controller}
                transition={{
                    default: { duration: strokeTime, ease: 'easeInOut' },
                    fill: { duration: fillTime, ease: [1, 0, 0.8, 1] },
                }}
            />
        </motion.svg>
    )
}

export default SVG
