import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Home'

function App() {
  return (
    <body className="bg-seashell dark:bg-jet text-jet dark:text-seashell">
      <div className="App">
        <Home></Home>
      </div>
    </body>
  );
}

export default App;
