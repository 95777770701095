import React from 'react'
import { delay, motion, useAnimationControls } from 'framer-motion'
import { useEffect } from 'react'
import hash_shape from './assets/hash-shape.svg'
import o_shape from './assets/o-shape.svg'
import Dash from './components/Dash'
import Dash2 from './components/Dash2'
import O from './components/OShape'
import H from './components/HashShape'
import dash2 from './assets/dash2.svg'

const Portfolio = () => {
    const aboutMeHeaderControls = useAnimationControls()
    const projectsHeaderControls = useAnimationControls()
    const oShapeControls = useAnimationControls()
    const hashShapeControls = useAnimationControls()
    useEffect(() => {
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const targetElement = entry.target as HTMLElement
                    if (targetElement.classList.contains('invisible')) {
                        // console.log('intersecting')
                        targetElement.style.animation = 'none'
                        const a = targetElement.offsetHeight
                        targetElement.style.animation = ''
                    }
                    targetElement.classList.remove('invisible')
                }
            })
        }

        const handleSVGIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const targetElement = entry.target
                    const name = targetElement.getAttribute('name')
                    console.log(name)
                    if (name === 'about-me') {
                        aboutMeHeaderControls.start('visible')
                    } else if (name === 'projects') {
                        projectsHeaderControls.start('visible')
                    } else if (name === 'o-shape') {
                        oShapeControls.start('visible')
                    } else if (name === 'hash-shape') {
                        hashShapeControls.start('visible')
                    }
                }
            })
        }

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0, // Adjust this threshold value as needed
        }

        const observer = new IntersectionObserver(handleIntersection, options)
        const svgObserver = new IntersectionObserver(handleSVGIntersection, options)

        const about_me_content = document.getElementById('about-me-content')
        const about_me_skills = document.getElementById('about-me-skills')
        const o_shape = document.getElementById('o-shape')
        const about_me_header = document.getElementById('about-me-header')?.children[0]
        const projects_header = document.getElementById('projects-header')?.children[0]
        const o_shape_svg = document.getElementById('o-shape-container')?.children[0].children[0]
        const hash_shape_svg = document.getElementById('hash-shape-container')?.children[0].children[0]

        // console.log(document.getElementById('about-me-header'));
        // console.log(document.getElementById('about-me-header')?.children);

        if (about_me_content) {
            observer.observe(about_me_content)
        }
        if (about_me_skills) {
            observer.observe(about_me_skills)
        }
        if (o_shape) {
            observer.observe(o_shape)
        }
        if (about_me_header) {
            // console.log(about_me_header)
            svgObserver.observe(about_me_header)
        }
        if (projects_header) {
            svgObserver.observe(projects_header)
        }
        if (o_shape_svg) {
            svgObserver.observe(o_shape_svg)
        }
        if (hash_shape_svg) {
            svgObserver.observe(hash_shape_svg)
        }

        return () => {
            observer.disconnect()
            svgObserver.disconnect()
        }
    }, [])

    const titleGreetingVariants = {
        initial: {
            opacity: 0,
            y: '-100%',
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.7,
                type: 'spring',
                stiffiness: 500,
            },
        },
    }
    const titleNameVariants = {
        initial: {
            opacity: 0,
            y: '100%',
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.7,
                type: 'spring',
                stiffiness: 500,
            },
        },
    }
    const titleRoleVariants = {
        initial: {
            opacity: 0,
            y: '100%',
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.1,
                duration: 0.7,
                type: 'spring',
                stiffiness: 500,
            },
        },
    }
    const titleDescriptionVariants = {
        initial: {
            opacity: 0,
            y: '100%',
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.1,
                duration: 0.7,
                type: 'spring',
                stiffiness: 500,
            },
        },
    }

    return (
        <>
            <div className="flex flex-row justify-end">
                <a
                    href="https://github.com/PiAreSquared"
                    target='_blank'

                    className="pt-10 pr-8 transition-colors duration-300 font-fira-sans hover:text-fire">
                    <span title='Github'>
                        <svg className="w-6 h-6 md:w-8 md:h-8" viewBox="0 0 24 24" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 0C5.37 0 0 5.37 0 12c0 5.3 3.43 9.8 8.2 11.4.6.1.8-.3.8-.6v-2.1c-3.3.7-4-1.6-4-1.6-.5-1.3-1.2-1.7-1.2-1.7-1-.7.1-.7.1-.7 1.1.1 1.7 1.1 1.7 1.1 1 1.7 2.6 1.2 3.2.9.1-.7.4-1.2.7-1.5-2.5-.3-5-1.3-5-5.8 0-1.3.5-2.4 1.1-3.2-.1-.3-.5-1.5.1-3.2 0 0 1-.3 3.3 1.2.9-.3 1.9-.4 2.9-.4s2 .1 2.9.4c2.3-1.5 3.3-1.2 3.3-1.2.6 1.7.2 2.9.1 3.2.6.8 1.1 1.9 1.1 3.2 0 4.5-2.5 5.5-5 5.8.4.4.8 1.1.8 2.3v3.4c0 .3.2.7.8.6 4.8-1.6 8.2-6.1 8.2-11.4C24 5.37 18.63 0 12 0z"
                                />
                        </svg>
                    </span>
                </a>
                <a
                    href="https://linkedin.com/in/vishalmuthuraja"
                    target='_blank'
                    className="pt-10 pr-10 transition-colors duration-300 font-fira-sans hover:text-fire">
                    <span title='LinkedIn'>
                        <svg className="w-6 h-6 md:w-8 md:h-8" viewBox="0 0 16 16" fill="currentColor">
                            <path 
                                d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                        </svg>
                    </span>
                </a>
            </div>
            <div className="flex flex-col h-screen px-8 pt-16 pb-24 bg-gray-100 lg:px-30 sm:px-16 md:px-24 md:pt-48 md:pb-56 xl:px-36 2xl:px-48">
                <div className="flex flex-row lg:py-28">
                    <div className="flex flex-col max-w-xl">
                        <motion.p
                            variants={titleGreetingVariants}
                            initial="initial"
                            animate="animate"
                            className="text-xl font-fira-sans text-fire tiny:text-3xl xxs:text-3xl">
                            hello there, my name is
                        </motion.p>
                        <div className="mt-4">
                            <motion.h1
                                variants={titleNameVariants}
                                initial="initial"
                                animate="animate"
                                className="tiny:text-4xl xxs:text-[42px] xs:text-5xl font-fingerpaint text-[32px] md:text-6xl">
                                vishal muthuraja.
                            </motion.h1>
                            <motion.p
                                variants={titleRoleVariants}
                                initial="initial"
                                animate="animate"
                                className="mt-2 text-lg xxs:text-xl font-fira-sans opacity-80 sm:text-2xl">
                                <span className="hidden xxs:inline">computer </span>engineer •{' '}
                                <span className="hidden md:inline">software engineer</span>
                                <span className="inline md:hidden">developer</span> • student
                            </motion.p>
                        </div>
                        <motion.p
                            variants={titleDescriptionVariants}
                            initial="initial"
                            animate="animate"
                            className="mt-8 font-fira-sans text-md sm:text-lg">
                            I am a computer engineering student at Purdue University. I love working on my projects, which range from
                            working with embedded systems to using AI to power new products. In my free time, I enjoy traveling and playing
                            sports like soccer and basketball.
                        </motion.p>
                    </div>
                    <div className="hidden pl-12 size-full min-h-96">
                        <div className="flex items-center justify-center w-full h-full border-2 shape shape-square boarder-jet dark:border-seashell">
                            <p>insert shape animation here</p>
                        </div>
                        {/* <canvas id="canvas" style="width: 100%; height: 100%;"></canvas> */}
                    </div>
                </div>

                {/* ABOUT ME SECTION */}

                <div id="about-me" className="flex flex-row items-center justify-center mt-48 sm:mt-32 md:mt-56 lg:justify-end">
                    <div className="flex flex-row-reverse justify-end">
                        <div id="about-me-header" className="relative">
                            <Dash animationController={aboutMeHeaderControls} name="about-me" />
                            {/* <img src={dash} className="h-78 transform w-[178px] rotate-3" alt="dash" /> */}
                            <p className="absolute text-4xl font-extrabold tracking-tighter transform -translate-x-1/2 -translate-y-1/2 text-jet animate-fadeIn font-petrona left-[51%] top-[48%] w-[145px] sm:text-4xl">
                                about me
                            </p>
                        </div>
                    </div>
                </div>

                <div id="about-me-content" className="flex flex-row-reverse justify-between invisible mt-8 animate-fadeIn">
                    <div className="flex flex-col md:ml-16 lg:ml-36 2xl:ml-0">
                        {/* <p className="flex-row mb-3 text-md sm:text-lg">I'll never forget the sense of awe I felt as a kid watching my dad effortlessly command the computer with a few keystrokes on that old green and black terminal screen. To me, it looked like he was communicating in some secret code with the machine. From that early spark, a passion was lit to understand the technology that seemed so magical at the time. Growing up in the heart of Silicon Valley's technological renaissance, I was determined to not just demystify computers, but to innovate and shape the future myself.</p>
                    <p className="flex-row mb-3 text-md sm:text-lg">At Purdue, I've immersed myself in exploring the intersection of hardware and software through coursework in areas like digital systems, computer networks, as well as data structures and algorithms. I've complemented my classes with hands-on projects, internships at companies like Cisco Systems, Collins Aerospace, and research in fields like computer vision and autonomous systems.</p>
                    <p className="flex-row mb-3 text-md sm:text-lg">My passion lies in using technology to develop impactful solutions that improve people's lives. As a founder of Nize Systems Inc., I led a team that built an RF-based attendance automation product aimed at streamlining operations for K-12 schools. I've also contributed to open-source projects utilizing skills in cloud computing, machine learning, data analysis, and full-stack development.</p>
                    <p className="flex-row mb-3 text-md sm:text-lg">I'm constantly driven to expand my knowledge horizons and push the boundaries of what's possible with software and hardware. My experiences have instilled in me the value of creativity, attention to detail, and collaboration to turn ideas into reality. I look forward to opportunities that allow me to innovate and have a positive real-world impact through the power of computer science and engineering.</p>  */}
                        <p className="flex-row mb-8 lg:text-justify font-fira-sans text-md sm:text-lg">
                            My passion lies in leveraging technology to develop solutions that improve people's lives. I'm constantly driven
                            to expand my skillset and push the boundaries of what's possible with software and hardware. My experiences have
                            shown me the value of creativity, attention to detail, and collaboration to turn ideas into reality. I look
                            forward to opportunities that allow me to innovate and have a positive real-world impact through the power of
                            computer science and engineering.
                        </p>
                        <p className="flex-row mb-3 font-fira-sans text-md sm:text-lg">
                            Here are some technologies that I have experience working with recently:
                        </p>
                        <div
                            id="about-me-skills"
                            className="flex-row justify-around invisible hidden mt-4 font-fira-sans sm:flex animate-fadeIn">
                            <div className="flex flex-col">
                                <div className="mb-8">
                                    <p className="font-bold text-md animate-fadeIn sm:text-lg">Languages</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">Python</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">JavaScript/TypeScript</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">C/C++</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Java</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">GoLang</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Cloud</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">Amazon AWS</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Microsoft Azure</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="mb-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Frameworks</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">React.js</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Vue.js</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Flask</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Django</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Databases</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">MongoDB</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">PostgreSQL</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">SQL</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-col ml-8">
                                <div className="mb-8 mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Misc</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">Git VCS</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Linux/UNIX systems</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Docker and K8s</li>
                                    </ul>
                                </div>
                                <div className="mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Machine Learning</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">PyTorch</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="flex-row justify-around hidden mt-4 tiny:flex font-fira-sans sm:hidden">
                            <div className="flex flex-col">
                                <div className="mb-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Languages</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">Python</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Typescript</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">C/C++</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Java</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">GoLang</li>
                                    </ul>
                                </div>
                                <div className="mb-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Cloud</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">AWS</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Azure</li>
                                    </ul>
                                </div>
                                <div className="mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Machine Learning</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">PyTorch</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="mb-8 mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Databases</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">MongoDB</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">PostgreSQL</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">SQL</li>
                                    </ul>
                                </div>
                                <div className="mb-8 mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Misc</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">Git VCS</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">*NIX systems</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Docker, K8s</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Frameworks</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">React.js</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Vue.js</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Flask</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Django</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row mt-4 justify-evenly font-fira-sans tiny:hidden">
                            <div className="flex flex-col">
                                <div className="mb-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Languages</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">Python</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Typescript</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">C/C++</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Java</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">GoLang</li>
                                    </ul>
                                </div>
                                <div className="mb-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Cloud</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">AWS</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Azure</li>
                                    </ul>
                                </div>
                                <div className="mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Machine Learning</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">PyTorch</li>
                                    </ul>
                                </div>
                                <div className="mb-8 mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Databases</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">MongoDB</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">PostgreSQL</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">SQL</li>
                                    </ul>
                                </div>
                                <div className="mb-8 mr-8">
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Misc</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">Git VCS</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">*NIX systems</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Docker, K8s</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className="text-lg font-bold text-md animate-fadeIn sm:text-lg">Frameworks</p>
                                    <ul className="ml-4 list-disc">
                                        <li className="animate-fadeIn text-md sm:text-lg">React.js</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Vue.js</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Flask</li>
                                        <li className="animate-fadeIn text-md sm:text-lg">Django</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:flex flex-col justify-center 2xl:min-w-[40rem] lg:-translate-x-[25rem] 2xl:-translate-x-[30rem] lg:transform">
                        {/* <div id="o-shape-container" className="w-0 transform animate-rotate 2xl:w-[40rem]"> */}
                        <div id="o-shape-container" className="h-full">
                            <O animationController={oShapeControls} name="o-shape" />
                        </div>
                        {/* <img id="o-shape" src={o_shape} className="w-0 transform invisible animate-rotate 2xl:w-[40rem]" alt="circle" /> */}
                    </div>
                </div>

                {/* EXPERIENCE SECTION */}

                <div className="flex flex-row items-center justify-center mt-56 lg:justify-start">
                    <div id="projects-header" className="relative transform -translate-x-[15px]">
                        <Dash2 animationController={projectsHeaderControls} name="projects" />
                        <p className="absolute text-4xl font-extrabold tracking-tighter transform -translate-x-1/2 -translate-y-1/2 text-jet animate-fadeIn font-petrona left-[53%] top-[46%] w-[145px] min-w-fit sm:text-4xl">
                            projects
                        </p>
                    </div>
                </div>

                <div className="flex flex-row justify-between">
                    <div className="flex flex-col w-full group">
                        <div className="pt-12 pb-8 duration-300 group-hover:hover:opacity-100 group-hover:opacity-70 hover:cursor-auto">
                            <div className="max-w-3xl">
                                <div className="mb-3">
                                    <p className="text-3xl font-semibold font-petrona dark:text-pistachio text-pistachio-dark">
                                        espFrame
                                        {/* <span className="ml-24 opacity-50">// <span className="text-xl">skill1, skill2</span></span> */}
                                    </p>
                                </div>
                                <div className="text-lg sm:text-md font-fira-sans font-regular">
                                    <p>
                                        A project that leverages an ESP32 + framed e-ink display to present live data, like current weather
                                        conditions and live bus schedules. Aimed at providing users with quick, smartphone-independent view
                                        of essential information, this low-power, easy-on-the-eyes setup is ideal under any lighting. It
                                        uses hardware like the ESP32 board and an e-ink display, and includes software like the ESP-IDF,
                                        custom display libraries, and HTTP client libraries for data fetching.
                                    </p>
                                </div>
                                <div className="mt-1 text-lg sm:text-md font-fira-sans font-regular">
                                    <p className="opacity-100">
                                        learn more{' '}
                                        <a
                                            href="https://github.com/PiAreSquared/espFrame"
                                            className="duration-300 text-fire hover:cursor-pointer hover:text-seashell">
                                            here →
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="py-8 duration-300 group-hover:hover:opacity-100 group-hover:opacity-70 hover:cursor-auto">
                            <div className="max-w-3xl">
                                <div className="mb-3">
                                    <p className="text-3xl font-semibold font-petrona dark:text-pistachio text-pistachio-dark">
                                        Clipper
                                        {/* <span className="ml-24 opacity-50">// <span className="text-xl">skill1, skill2</span></span> */}
                                    </p>
                                </div>
                                <div className="text-lg sm:text-md font-fira-sans font-regular">
                                    <p>
                                        An AI-driven solution automating the generation of sports highlights. Clipper derives a time-series
                                        signal from analyzing both the audio and video of a sports game, allowing it to find the critical
                                        moments. With this, Clipper clips and stitches together a custom highlights reel based on the user's
                                        requests and customizations.
                                    </p>
                                </div>
                                <div className="hidden mt-1 text-lg sm:text-md font-fira-sans font-regular">
                                    <p className="opacity-100">
                                        read more{' '}
                                        <span className="duration-300 text-fire hover:cursor-pointer hover:text-seashell">here →</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="py-8 duration-300 group-hover:hover:opacity-100 group-hover:opacity-70 hover:cursor-auto">
                            <div className="max-w-3xl">
                                <div className="mb-3">
                                    <p className="text-3xl font-semibold font-petrona dark:text-pistachio text-pistachio-dark">
                                        Presence
                                        {/* <span className="ml-24 opacity-50">// <span className="text-xl">skill1, skill2</span></span> */}
                                    </p>
                                </div>
                                <div className="text-lg sm:text-md font-fira-sans font-regular">
                                    <p>
                                        A product I created which streamlines the process of taking attendance. Consisting of a wall-mounted
                                        RFID boxes and a cloud-based software, Presence collects and manages the attendance records of
                                        schools. Now managed by my startup, Nize Inc. aims to expand this solution to other schools to
                                        increase effeciency and reduce error.
                                    </p>
                                </div>
                                <div className="mt-1 text-lg sm:text-md font-fira-sans font-regular">
                                    <p className="opacity-100">
                                        read more{' '}
                                        <a
                                            href="https://nize.education"
                                            className="duration-300 text-fire hover:cursor-pointer hover:text-seashell">
                                            here →
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="hash-shape-container"
                        className="hidden xl:flex flex-col justify-center xl:min-w-[15rem] 2xl:min-w-[35rem] xl:translate-x-[80%] xl:transform">
                        <H animationController={hashShapeControls} name="hash-shape" />
                        {/* <img src={hash_shape} className="md: w-0 rotate-[94deg] transform lg:w-5/6" alt="hash" /> */}
                    </div>
                </div>

                <footer className="pb-8 bg-gray-900 pt-36">
                    <div className="container flex flex-col items-center mx-auto">
                        <div className="relative flex space-x-4">
                            <a href="#" className="transition-colors duration-300 font-fira-sans hover:text-fire">
                                home
                            </a>
                            {/* <div>
                                <a
                                    // href="#"
                                    className="transition-colors duration-300 peer/ink font-fira-sans hover:cursor-not-allowed opacity-40">
                                    ink{' '}
                                </a> */}
                            {/* <span
                                className="absolute left-0 px-2 py-1 text-sm transition-opacity rounded shadow opacity-0 pointer-events-none bg-fire/20 -top-8 w-max text-seashell peer-hover/ink:opacity-100">
                                Page doesn't exist yet.
                            </span> */}
                            {/* </div> */}
                            <a
                                href="https://github.com/PiAreSquared"
                                className="transition-colors duration-300 font-fira-sans hover:text-fire">
                                github
                            </a>
                            <a
                                href="https://linkedin.com/in/vishalmuthuraja"
                                className="transition-colors duration-300 font-fira-sans hover:text-fire">
                                linkedin
                            </a>
                        </div>
                        <p className="hidden mt-4 -mb-1 font-normal sm:block font-fira-sans">
                            © 2024 Vishal Muthuraja. All rights reserved.
                        </p>
                        <p className="mt-4 -mb-1 font-normal sm:hidden font-fira-sans">© 2024 Vishal Muthuraja.</p>
                        <p className="font-normal sm:hidden font-fira-sans">All rights reserved.</p>
                        <p className="font-fira-sans font-extralight">
                            <span className="font-light">production</span> v0.0.1
                        </p>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Portfolio
